



















































































































import useUser from '@/use/user'
import { computed, defineComponent, reactive } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

interface StepError {
  step: number;
  error: string;
}

export default defineComponent({
  components: {
    OVehicleDataForm: () => import('@/components/organisms/o-vehicle-data-form.vue'),
    OVehicleDetailsForm: () => import('@/components/organisms/o-vehicle-details-form.vue')
  },

  setup(props, { root }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root })

    const model = reactive<{
      stepper: number;
      data: any;
      details: any;
      scanner: boolean;
    }>({
      stepper: 1,
      data: {},
      details: {},
      scanner: false
    })

    const state = reactive({
      scanner: false,
      loading: false,
      errors: computed((): StepError[] => {
        const errors: StepError[] = []

        if (!model.data.registrationNumber) errors.push({ step: 1, error: 'Podaj numer rejestracyjny pojazdu.' })
        if (!model.data.vehicleBrand) errors.push({ step: 1, error: 'Podaj markę pojazdu.' })
        if (!model.data.vehicleModel) errors.push({ step: 1, error: 'Podaj model pojazdu.' })
        if (!model.data.vinNumber) errors.push({ step: 1, error: 'Podaj numer VIN pojazdu.' })

        if (!model.data.productionYear) errors.push({ step: 1, error: 'Podaj rok produkcji pojazdu.' })
        else if (model.data.productionYear < 1970) errors.push({ step: 1, error: 'Podaj rok produkcji' })
        else if (model.data.productionYear > new Date().getFullYear()) errors.push({ step: 1, error: `Samochód z roku ${model.data.productionYear} nie został jeszcze wyprodukowany. Obecnie mamy ${new Date().getFullYear()} rok. Proszę podać poprawny rok produkcji.` })

        if (!model.details.emissionStandard) errors.push({ step: 2, error: 'Podaj normę emisji spalin.' })

        if ((hasAccessTo.value('employee') || hasManyCompanies.value()) && !model.details.company) {
          errors.push({ step: 2, error: 'Wybierz firmę, do której chcesz dodać pojazd.' })
        }

        return errors
      }),
    })

    const isStepEditable = (step: number) => {
      // return model.stepper > step
      return step != 3
    }

    const isStepComplete = (step: number) => {
      return model.stepper > step && !state.errors.find(error => error.step == step)
    }

    const isStepValid = (step: number) => {
      return !state.errors.find(error => error.step == step)
    }

    const isFormValid = computed(() => !state.errors.length)

    const goToErrors = () => {
      let lowest = Number.POSITIVE_INFINITY
      let highest = Number.NEGATIVE_INFINITY

      let tmp: number

      for (let i = state.errors.length - 1; i >= 0; i--) {
        tmp = state.errors[i].step
        if (tmp < lowest) lowest = tmp
        if (tmp > highest) highest = tmp
      }

      model.stepper = lowest
    }

    const onSubmit = () => {
      const data = {
        details: {
          registrationNumber: model.data.registrationNumber || undefined,
          firstRegistrationDate: model.data.firstRegistrationDate || undefined,
          registrationCertificateHolderName: model.data.registrationCertificateHolderName || undefined,
          registrationCertificateHolderNumber: model.data.registrationCertificateHolderNumber || undefined,
          registrationCertificateHolderAddress: model.data.registrationCertificateHolderAddress || undefined,
          registrationCertificateOwnerName: model.data.registrationCertificateOwnerName || undefined,
          registrationCertificateOwnerNumber: model.data.registrationCertificateOwnerNumber || undefined,
          registrationCertificateOwnerAddress: model.data.registrationCertificateOwnerAddress || undefined,
          vehicleBrand: model.data.vehicleBrand || undefined,
          vehicleModel: model.data.vehicleModel || undefined,
          vehicleType: model.data.vehicleType || undefined,
          vinNumber: model.data.vinNumber || undefined,
          maximumGrossVehicleWeight: parseFloat(model.data.maximumGrossVehicleWeight) || undefined,
          grossVehicleWeight: parseFloat(model.data.grossVehicleWeight) || undefined,
          grossVehicleCombinationWeight: parseFloat(model.data.grossVehicleCombinationWeight) || undefined,
          vehicleCurbWeight: parseFloat(model.data.vehicleCurbWeight) || undefined,
          registrationCertificateValidityPeriod: model.data.registrationCertificateValidityPeriod || undefined,
          registrationCertificateIssueDate: model.data.registrationCertificateIssueDate || undefined,
          vehicleCategory: model.data.vehicleCategory || undefined,
          approvalCertificateNumber: model.data.approvalCertificateNumber || undefined,
          axlesNumber: parseInt(model.data.axlesNumber) || undefined,
          maximumGrossTrailerWeightWithBrakes: parseFloat(model.data.maximumGrossTrailerWeightWithBrakes) || undefined,
          maximumGrossTrailerWeightWithoutBrakes: parseFloat(model.data.maximumGrossTrailerWeightWithoutBrakes) || undefined,
          engineCapacity: parseFloat(model.data.engineCapacity) || undefined,
          maximumEnginePower: parseFloat(model.data.maximumEnginePower) || undefined,
          fuelType: model.data.fuelType || undefined,
          powerToWeightRatio: parseFloat(model.data.powerToWeightRatio) || undefined,
          seatsNumber: parseInt(model.data.seatsNumber) || undefined,
          standingPlacesNumber: parseInt(model.data.standingPlacesNumber) || undefined,
          vehicleKind: model.data.vehicleKind || undefined,
          productionYear: parseInt(model.data.productionYear) || undefined,
          loadCapacity: parseFloat(model.data.loadCapacity) || undefined,
          maximumAxleLoad: parseFloat(model.data.maximumAxleLoad) || undefined,
          vehicleCardNumber: model.data.vehicleCardNumber || undefined
        },
        emissionStandard: model.details.emissionStandard || undefined,
        isPool: model.details.isPool ? true : false,
        company: model.details.company ? model.details.company.id || undefined : undefined
      }

      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance
      state.loading = true

      axiosInstance
        .post('vehicle', data)
        .then(({ data: { vehicle } }) => {
          root.$router.push({ name: 'panel.vehicle.view', params: { id: vehicle.id } })
        })
        .catch(error => console.log(error))
        .finally(() => state.loading = false)
    }

    return { hasAccessTo, hasManyCompanies, state, model, isStepEditable, isStepComplete, isStepValid, isFormValid, goToErrors, onSubmit }
  }
})
